import BannerDriver from './drivers/driver-banner'
import ImageDriver from './drivers/driver-base'
import StorageDriver from './drivers/driver-storage'
import UploadCareDriver from './drivers/driver-uploadcare'

export function getDriver(source: string) {
  /*
   * The temporary solution to migrate links to local banners
   * in RecRoom API
   */
  if (/\/img\/banner/.test(source)) {
    return new BannerDriver(source)
  }

  if (/cdn\.eonx\.net|uploadcare|ucarecdn/.test(source)) {
    return new UploadCareDriver(source)
  } else if (/storage/.test(source)) {
    return new StorageDriver(source)
  }

  return new ImageDriver(source)
}

export default getDriver

export enum ProviderDefaultUrlMap {
  bupa = 'uploadcare://52e1e522-a044-4474-b6a8-1a55460e667a',
}

type Provider = keyof typeof ProviderDefaultUrlMap

export function getProviderDefaultUrl(provider: Provider) {
  return getDriver(ProviderDefaultUrlMap[provider]).url()
}
