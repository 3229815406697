import { computed, reactive } from 'vue'
import { PaymentMethod } from '/~/types/api'
import emitter from '/~/core/emitter'
import { FlowType, getOrderType } from '/~/composables/checkout/checkout-types'
import {
  usePaymentMethods,
  filterByType,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'

type FlowPaymentMethods = {
  items: PaymentMethod[]
  fetching: boolean
  loaded: boolean
}

const initialValues: FlowPaymentMethods = {
  items: [],
  fetching: false,
  loaded: false,
}

const paymentMethods = reactive<{ [K in FlowType]: FlowPaymentMethods }>({
  Order: initialValues,
  PaymentOrder: initialValues,
  StatementOrder: initialValues,
  BatchOrder: initialValues,
  QRPayOrder: initialValues,
  TopUpOrder: initialValues,
  WithdrawOrder: initialValues,
  QuickBuyOrder: initialValues,
  PointsProgramOrder: initialValues,
  AutoPay: initialValues,
})

export function useFlowPaymentMethods(
  currentFlowType: FlowType,
  payeeId?: string,
  payeeType?: string
) {
  const { getPaymentMethods, isMethodAvailable, initiatingAccountsIds } =
    usePaymentMethods()
  const { splitPaymentOrderAllowed } = useProvider()

  const currentOrderType = getOrderType(currentFlowType)

  const currentFlowMethods = paymentMethods[currentOrderType]

  const fetching = computed(() => currentFlowMethods.fetching)
  const loaded = computed(() => currentFlowMethods.loaded)
  const ready = computed(
    () => currentFlowMethods.loaded && !currentFlowMethods.fetching
  )

  const creditCards = computed(() =>
    filterByType(currentFlowMethods.items, PaymentMethodType.creditCard)
  )
  const bankAccounts = computed(() =>
    filterByType(currentFlowMethods.items, PaymentMethodType.bankAccount)
  )
  const points = computed(() =>
    filterByType(currentFlowMethods.items, PaymentMethodType.points)
  )
  const payIdItems = computed(() =>
    filterByType(currentFlowMethods.items, PaymentMethodType.payId)
  )
  const payIds = computed(() => [
    {
      description: 'Send funds to our PayID for instant payout',
      ...(payIdItems.value.length
        ? { ...payIdItems.value[0] }
        : {
            type: PaymentMethodType.payId,
            state: payeeType === 'bpay' ? 'restricted' : null,
          }),
    },
  ])

  const noCreditCards = computed(() => ready.value && !creditCards.value.length)
  const noBankAccounts = computed(
    () => ready.value && !bankAccounts.value.length
  )

  const isCreditCardsAvailable = computed(() =>
    isMethodAvailable(PaymentMethodType.creditCard, currentOrderType)
  )
  const isBankAccountsAvailable = computed(() =>
    isMethodAvailable(PaymentMethodType.bankAccount, currentOrderType)
  )

  const isPointsAvailable = computed(() =>
    isMethodAvailable(PaymentMethodType.points, currentOrderType)
  )

  const isPayIdAvailable = computed(() =>
    isMethodAvailable(PaymentMethodType.payId, currentOrderType)
  )

  const isPaymentMethodsEmpty = computed(() => {
    return (
      !bankAccounts.value.length &&
      !creditCards.value.length &&
      !isPayIdAvailable.value &&
      !(isPointsAvailable.value && !splitPaymentOrderAllowed(currentOrderType))
    )
  })

  async function getCurrentFlowPaymentMethods() {
    currentFlowMethods.items = await getPaymentMethods(
      currentOrderType,
      payeeId
    )
  }

  async function fetchPaymentMethods() {
    currentFlowMethods.fetching = true

    try {
      await getCurrentFlowPaymentMethods()
      currentFlowMethods.loaded = true
    } catch (error) {
      console.error('payment-methods', error)
    } finally {
      currentFlowMethods.fetching = false
    }
  }

  function initPaymentMethodsUpdates() {
    emitter.on('payment-methods:updated', getCurrentFlowPaymentMethods)
  }

  function stopPaymentMethodsUpdates() {
    emitter.off('payment-methods:updated', getCurrentFlowPaymentMethods)
  }

  return {
    fetching,
    loaded,
    ready,
    creditCards,
    bankAccounts,
    points,
    payIds,
    noCreditCards,
    noBankAccounts,
    isCreditCardsAvailable,
    isBankAccountsAvailable,
    isPointsAvailable,
    isPayIdAvailable,
    isPaymentMethodsEmpty,
    fetchPaymentMethods,
    initiatingAccountsIds,
    initPaymentMethodsUpdates,
    stopPaymentMethodsUpdates,
  }
}
