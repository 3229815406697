import Vue, { reactive } from 'vue'
import type { RequestOptions } from '/~/types/api'
import emitter from '/~/core/emitter'
import { DefaultJwtStrategy } from '/~/core/Jwt/JwtStrategy/DefaultJwtStrategy'
import modal from '/~/core/mdl'
import LoginOtp from '/~/extensions/otp/composables/core/Otp/LoginOtp'
import { OtpLocalStorage } from '/~/extensions/otp/composables/core/OtpLocalStorage'
import { getCookie } from '/~/utils/cookies'
import { useLogger } from '/~/composables/logger'
import { useProvider } from '/~/composables/provider'
import { Jwt } from '/~/core'
import { renderMaintenance } from '/~/templates/maintenance'

const V1_REGEXP = /\/1.0\//
const V3_REGEXP = /\/v3\//

export const ERROR_DISPLAY_DURATION = 15000
export const ERROR_SERVER_EXCEPTION_MESSAGE =
  'We are having technical difficulties and are actively working on a fix. Please try again in few minutes.'

const logger = useLogger('api')

export const jwt = new Jwt()

jwt.setJwtStrategy(new DefaultJwtStrategy())

const getUrl = (url: string, { baseURL, query }: RequestOptions) => {
  if (typeof query === 'object' && Object.keys(query).length > 0) {
    url = url + '?' + new URLSearchParams(query).toString()
  }
  if (V3_REGEXP.test(url)) return eonx.hosts.api.v3 + url
  if (/^(http|\/\/|\/\d)/.test(url)) return url
  if (baseURL) return baseURL + url
  return eonx.hosts.api.v1 + url
}

export const showNotification = (
  message: string | string[],
  severity: string,
  duration: number = ERROR_DISPLAY_DURATION
) => {
  if (typeof message === 'string') {
    Vue.notify({
      text: message,
      type: severity,
      duration,
    })
  } else if (
    Array.isArray(message) &&
    message.length > 0 &&
    typeof message[0] === 'string'
  ) {
    message.forEach((msg) => {
      showNotification(msg, severity, duration)
    })
  }
}

export function notify(
  content: any,
  severity: string,
  options: RequestOptions = {}
) {
  if (!content) return

  if (options.notify === false) return

  const messageText =
    typeof content === 'string'
      ? content.replace(/(^"|"$)/g, '')
      : content.data?.message ?? content.message

  const validationErrors = content.data?.errors ?? content.errors

  showNotification(messageText || validationErrors, severity)
}

const getOptions = async (url: string, options: RequestOptions = {}) => {
  const { requireAuth = true } = options
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  } as Record<string, string>

  if (requireAuth) {
    // /v3/ endpoints use bearer token instead of csrf
    if (V3_REGEXP.test(url)) {
      let token

      if (options.token) {
        // If token is passed in options, use it
        token = options.token
      } else {
        // Otherwise, get it from jwt service
        try {
          token = await jwt.get()
        } catch (error) {
          console.debug('JWT get failed')
          throw error
        }
      }

      if (token) {
        defaultHeaders.Authorization = `Bearer ${token}`
      }
      // /1.0/ endpoints use csrf token
    } else if (options.method) {
      defaultHeaders['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN')
      // Fingerprint header is not allowed in v3 api
      // if (fingerprintHash) defaultHeaders.fingerprint = fingerprintHash
    }
  }

  return {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options.headers || {}),
    },
  }
}

function normalizeResponse<T>(
  response: Response,
  data: T,
  options: RequestOptions = {}
): T | { data: T; headers: Headers } {
  if (options.includeHeaders) {
    return {
      data,
      headers: response.headers,
    }
  } else {
    return data
  }
}

async function handle2faRedirect(response: Response) {
  const otp = reactive(new LoginOtp('login'))

  logger.debug('handle2faRedirect', response)

  try {
    const data = await response.json()

    if (data.jwt) {
      const otpStorage = otp.getStorage()

      otp.setJwt(data.jwt)
      otpStorage.put('lastOtp', {
        ...otpStorage.restore(),
        ...Object.fromEntries(
          Object.entries(otp).filter(([_, v]) => v !== undefined)
        ),
      })
      logger.debug(
        'Setting JWT from response 412 data',
        new Date(otp.jwt?.expiry ?? 0)
      )
    }

    if (otp.deliveryChannels.length) {
      emitter.emit('router:push', { name: 'request-code' })
    } else {
      logger.warn('No delivery channels available, redirect cancelled.')
    }
  } catch (error) {
    logger.error(error)
  }
}

export async function processResponseSuccess(
  response: Response,
  options: RequestOptions = {}
) {
  try {
    if (options.responseType === 'blob') {
      return await response
        .blob()
        .then((blob) => normalizeResponse(response, blob, options))
    } else {
      const responseJson = await response.json()

      notify(responseJson, 'success', options)
      return normalizeResponse(response, responseJson, options)
    }
  } catch (error) {
    logger.error(error)
  }
}

export async function processResponseError(
  response: Response,
  options: RequestOptions = {}
) {
  let parsedResponse

  try {
    parsedResponse = await response.clone().json()
  } catch (jsonParseError) {
    try {
      parsedResponse = await response.clone().text()
    } catch (textParseError) {
      parsedResponse = response
    }
  }

  if (V1_REGEXP.test(response.url) && response.status === 403) {
    options.notify = true
  }

  notify(parsedResponse, parsedResponse?.data?.severity ?? 'error', options)

  throw { status: response?.status, ...parsedResponse }
}

export async function processResponse(
  response: Response,
  options: RequestOptions = {}
) {
  const { loginType } = useProvider()
  const loginOtpStorage = new OtpLocalStorage('login')

  if (response.status >= 500) {
    showNotification(ERROR_SERVER_EXCEPTION_MESSAGE, 'error')
    options.notify = false
  }

  switch (response.status) {
    case 204:
      return response
    case 401:
      // TODO: Decouple return type from existence of eonx.user.
      // currently tied to 1.0/jwt for pre-login state.
      // Consider handling 1.0/jwt separately.
      if (!eonx.user) {
        return response
      }
      // keep this code in case /v3 endpoints also return 401
      loginOtpStorage.clear()
      eonx.user = null
      window.location.replace(
        loginType.value === 'oauth'
          ? `/login?intended=${window.location.pathname}`
          : '/logout'
      )
      break
    case 403:
      if (V1_REGEXP.test(response.url)) {
        // Set notify to true if not explicitly set
        options.notify ??= true
      } else if (!options.method || options.method === 'GET') {
        options.notify = false
      }
      break
    // 412 code: 2FA module enabled
    case 412:
      options.notify = false
      return handle2faRedirect(response)
    // 423 code: 2FA permanent account lock
    case 423:
      options.notify = false
      loginOtpStorage.clear()
      emitter.emit('signout')
      emitter.emit('router:push', { name: 'auth-account-locked' })
      break
    case 424:
      options.notify = false
      modal.show('unsupported-payees-detected')
      break
    case 429:
      options.notify = false

      loginOtpStorage.clear()

      // redirect temporary lock screen signin/otp endpoints
      // 429 code: 2FA temporarily account lock
      logger.debug('Redirecting to auth-account-temporarily-locked')
      emitter.emit('signout')
      emitter.emit('router:push', { name: 'auth-account-temporarily-locked' })
      break
    case 503:
    case 502:
      options.notify = false
      renderMaintenance()
      break
  }

  if (response.ok) {
    return processResponseSuccess(response, options)
  } else {
    return processResponseError(response, options)
  }
}

export class ApiWorker {
  static readonly MAX_TRIES = 1
  baseUrl: string

  constructor() {
    this.baseUrl = eonx.hosts.api.v1
  }

  async checkToken(url: string) {
    if (!V3_REGEXP.test(url)) {
      try {
        await this.get('/csrf-token', { requireAuth: false })
      } catch (e) {
        /* */
      }
    }
  }

  async get<T>(
    url: string,
    options: RequestOptions = {},
    retryCount = 0
  ): Promise<T> {
    const parsedUrl = getUrl(url, options)
    const parsedOptions = await getOptions(parsedUrl, options)

    const response = await fetch(parsedUrl, parsedOptions)

    // Check if the response status is 440 and if retries are still available
    if (response.status === 440 && retryCount < ApiWorker.MAX_TRIES) {
      try {
        console.debug(
          `Retrying request ${retryCount + 1} of ${ApiWorker.MAX_TRIES}`
        )
        await jwt.refetch()
      } catch (error) {
        console.error('Failed to refresh JWT:', error)
        throw error
      }

      return this.get(url, options, retryCount + 1)
    }

    return processResponse(response, options)
  }

  async fetch(url: string, options?: RequestOptions) {
    return this.get(url, options)
  }

  async post<T>(url: string, payload?: unknown, options?: RequestOptions) {
    return this.sendRequestWithPayload<T>('POST', url, payload, options)
  }

  async put<T>(url: string, payload: unknown, options?: RequestOptions) {
    return this.sendRequestWithPayload<T>('PUT', url, payload, options)
  }

  async patch<T>(url: string, payload: unknown, options?: RequestOptions) {
    return this.sendRequestWithPayload<T>('PATCH', url, payload, options)
  }

  async delete<T>(url: string, options?: RequestOptions) {
    return this.sendRequestWithPayload<T>('DELETE', url, {}, options)
  }

  async sendRequestWithPayload<T>(
    method: string,
    url: string,
    payload: unknown,
    options?: RequestOptions
  ) {
    await this.checkToken(url)
    return this.get<T>(url, {
      ...options,
      method,
      body: JSON.stringify(payload),
    })
  }
}

export default new ApiWorker()
