import { ref, computed } from 'vue'
import api from '/~/core/api'

const preferencesInfo = [
  {
    channel: 'sms',
    type: 'sms_global',
    title: 'SMS Global',
    text: 'Enable SMS to receive important updates and special offers straight to your phone. We only text essential information (no more than once a month).',
  },
  {
    channel: 'email',
    type: 'monthly_essentials',
    title: 'Monthly Essentials',
    text: 'Essential news, promos and other important updates related to you, including monthly points balance updates and exclusive offers.',
  },
  {
    channel: 'email',
    type: 'bonus_point_offers',
    title: 'Bonus Point Offers',
    text: 'Bonus points offers which may help you unlock rewards faster by earning more points on your typical spend, e.g., double points offers.',
  },
  {
    channel: 'email',
    type: 'product_offers',
    title: 'Product Offers',
    text: 'Special and exclusive product offers which may include two for one offers on particular products, etc.',
  },
  {
    channel: 'email',
    type: 'rewards_store_highlights',
    title: 'Reward Store Highlights',
    text: 'Updates on new reward store products, featured discounts on reward products and recommendations we think you may like.',
  },
]

const isCommunicationPreferencesLoading = ref(false)
const communicationPreferences = ref([])

const hasNoCommunicationPreferences = computed(
  () => communicationPreferences.value.length === 0
)
const smsPreferences = computed(() => {
  return communicationPreferences.value.filter(
    (preference) => preference.channel === 'sms'
  )
})
const emailPreferences = computed(() => {
  return communicationPreferences.value.filter(
    (preference) => preference.channel === 'email'
  )
})
const hasSmsPreferences = computed(() => smsPreferences.value.length > 0)
const hasEmailPreferences = computed(() => emailPreferences.value.length > 0)
const isCommunicationPreferencesChanged = computed(() => {
  return Boolean(
    communicationPreferences.value.find(
      (preference) => preference.initialActive !== preference.active
    )
  )
})

async function fetchCommunicationPreferences() {
  if (isCommunicationPreferencesLoading.value) {
    return
  }

  isCommunicationPreferencesLoading.value = true

  try {
    const response = await api.get('/v3/user-communications/')

    setPreferences(response.data)
  } catch (error) {
    console.error('user-communications', error)
  } finally {
    isCommunicationPreferencesLoading.value = false
  }
}

async function saveCommunicationPreferences() {
  if (isCommunicationPreferencesLoading.value) {
    return
  }

  isCommunicationPreferencesLoading.value = true

  const updatePromises = []

  communicationPreferences.value.forEach((preference) => {
    if (preference.initialActive === preference.active) {
      return
    }

    updatePromises.push(
      api
        .put(
          `/v3/user-communications/${preference.channel}/${preference.type}`,
          { active: preference.active },
          { notify: false }
        )
        .then(() => {
          preference.initialActive = preference.active
        })
    )
  })

  const responses = await Promise.allSettled(updatePromises)

  isCommunicationPreferencesLoading.value = false

  if (responses.find((response) => response.status === 'rejected')) {
    throw new Error(
      'Some preferences were not saved. Please try again in few minutes.'
    )
  }
}

function setPreferences(preferences) {
  communicationPreferences.value.slice(0, communicationPreferences.value.length)

  preferencesInfo.forEach((preferenceInfo) => {
    const preference =
      preferences.find((preference) => {
        return (
          preferenceInfo.channel === preference.channel &&
          preferenceInfo.type === preference.type
        )
      }) ?? {}

    communicationPreferences.value.push({
      ...preferenceInfo,
      active: preference.active ?? true,
      initialActive: preference.active ?? true,
    })
  })
}

function isSmsCommunicationPreference(preference) {
  return preference.channel === 'sms'
}

function isEmailCommunicationPreference(preference) {
  return preference.channel === 'email'
}

function getCommunicationPreferenceInfo(preference) {
  return preferencesInfo.find(
    (preferenceInfo) =>
      preferenceInfo.channel === preference.channel &&
      preferenceInfo.type === preference.subtype
  )
}

export function useCommunicationPreferences() {
  return {
    isCommunicationPreferencesLoading,
    communicationPreferences,
    hasNoCommunicationPreferences,
    smsPreferences,
    emailPreferences,
    hasSmsPreferences,
    hasEmailPreferences,
    isCommunicationPreferencesChanged,
    fetchCommunicationPreferences,
    saveCommunicationPreferences,
    isSmsCommunicationPreference,
    isEmailCommunicationPreference,
    getCommunicationPreferenceInfo,
  }
}
