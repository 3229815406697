import { Jwt } from '/~/core/jwt'
import { EnrolmentJwtStrategy } from '/~/core/Jwt/JwtStrategy/EnrolmentJwtStrategy'
import Otp from '/~/extensions/otp/composables/core/Otp'

export class EnrolmentOtp extends Otp {
  public async setJwt(token?: string) {
    if (!this.jwt) {
      this.jwt = new Jwt()
      this.jwt.setJwtStrategy(new EnrolmentJwtStrategy(this.channel))

      if (!this.jwt.data?.otp) {
        const token = await this.jwt.jwtTokenStrategy?.generateToken()

        this.jwt.set(token)
      }
    }

    if (token) {
      this.jwt.set(token)
    }
  }
}

export default EnrolmentOtp
