import { computed, reactive, ref } from 'vue'
import type {
  ApiResponseData,
  RewardsType,
  RebatesCalculateResponse,
  RebateTotalByType,
} from '/~/types/api'
import api from '/~/core/api'
import { useEstore } from '/~/extensions/estore/composables'
import { useRetailer } from '/~/extensions/giftcards/composables'
import { roundFigure } from '/~/utils/format/numeric'
import QuickBuyCheckout from '/~/composables/checkout/core/Checkout/QuickBuyCheckout'

const checkout = reactive(new QuickBuyCheckout())

type Query = {
  slug: string
  offerExternalId: string
  externalId: string
  id: string
  price: string
  value: string
  quantity: number
  amount: string
  giftRecipientId: string
  message: string
  count: number
  productId: string
}

const query = ref<Query>({} as Query)
const type = ref<RewardsType | null>(null)
const isQuickBuyInitializing = ref(false)
const rebateTotalByType = ref<RebateTotalByType[]>([])

const quickBuyProducts = computed(() => checkout.items)
const productsHasDelivery = computed(() => checkout.hasDeliverableItems)
const isAddressSelectionAllowed = computed(
  () => checkout.isAddressSelectionAllowed
)
const hasSelectedPaymentMethods = computed(
  () => checkout.hasSelectedPaymentMethods
)
const isPointsDisabled = computed(() => checkout.isPointsDisabled)
const isCreditCardsDisabled = computed(() => checkout.isCreditCardsDisabled)
const isBankAccountsDisabled = computed(() => checkout.isBankAccountsDisabled)
const isEwalletDisabled = computed(() => checkout.isEwalletDisabled)
const hasCoupons = computed(() => checkout.hasCoupons)
const payWithPayId = computed(() => checkout.payWithPayId)
const payWithPoints = computed(() => checkout.payWithPoints)
const payWithEwallet = computed(() => checkout.payWithEwallet)
const payWithCard = computed(() => checkout.payWithCard)
const payWithBankAccount = computed(() => checkout.payWithBankAccount)
const payWithCoupons = computed(() => checkout.payWithCoupons)
const subTotal = computed(() => checkout.subTotal)
const subTotalNoCoupons = computed(() => checkout.subTotalNoCoupons)
const subTotalWithProgramFees = computed(() => checkout.subTotalWithProgramFees)
const subTotalWithProgramFeesNoCoupons = computed(
  () => checkout.subTotalWithProgramFeesNoCoupons
)
const total = computed(() => checkout.total)
const cartAmount = computed(() => checkout.cartAmount)
const readyToPay = computed(() => checkout.readyToPay)
const isReadyForPayment = computed(() => checkout.isReadyForPayment)
const selectedAmount = computed(() => checkout.selectedAmount)
const isEnoughPointsOrder = computed(() => checkout.isEnoughPointsOrder)
const isEnoughPointsPaymentOrder = computed(() => checkout.isEnoughPointsOrder)
const pointsEarned = computed(() => checkout.pointsEarned)
const multipleSourcesSelected = computed(() => checkout.multipleSourcesSelected)
const loadingFees = computed(() => checkout.loadingFees)
const transactionFees = computed(() => checkout.transactionFees)
const totalFees = computed(() => checkout.totalFees)
const selectedPaymentInfo = computed(() => checkout.selectedPaymentInfo)
const isCouponVerifying = computed(() => checkout.isCouponVerifying)
const selectedPayId = computed({
  get() {
    return checkout.selectedPayId
  },
  set(_selectedPayId) {
    checkout.selectedPayId = _selectedPayId
  },
})
const selectedPoints = computed({
  get() {
    return checkout.selectedPoints
  },
  set(_selectedPoints) {
    checkout.selectedPoints = _selectedPoints
  },
})
const selectedEwallet = computed({
  get() {
    return checkout.selectedEwallet
  },
  set(_selectedEwallet) {
    checkout.selectedEwallet = _selectedEwallet
  },
})
const selectedCoupons = computed({
  get() {
    return checkout.selectedCoupons
  },
  set(_selectedCoupons) {
    checkout.selectedCoupons = _selectedCoupons
  },
})
const selectedCard = computed({
  get() {
    return checkout.selectedCard
  },
  set(_selectedCard) {
    checkout.selectedCard = _selectedCard
  },
})
const selectedBankAccount = computed({
  get() {
    return checkout.selectedBankAccount
  },
  set(_selectedBankAccount) {
    checkout.selectedBankAccount = _selectedBankAccount
  },
})
const resetMethods = checkout.resetCouponsAndPaymentMethods.bind(checkout)
const getTransactionFees = checkout.getTransactionFees.bind(checkout)
const finishPayment = checkout.reset.bind(checkout)
const preview = checkout.preview.bind(checkout)
const pay = checkout.pay.bind(checkout)
const reset = checkout.reset.bind(checkout)
const getPaymentSources = checkout.getPaymentSources.bind(checkout)
const verifyPromotionCodeCurrentOrder =
  checkout.verifyPromotionCodeCurrentOrder.bind(checkout)
const removeCartPromotionCode = checkout.removeCartPromotionCode.bind(checkout)
const checkoutByAccount = checkout.checkoutByAccount?.bind(checkout)
const isCardOrBankAccountMethodSelected = computed(
  () => checkout.isCardOrBankAccountMethodSelected
)
const cashbackRebateTotal = computed(
  () =>
    rebateTotalByType.value.find((item) => item.type === 'cashback_rebate')
      ?.amount ?? 0
)
const pointsRebateTotal = computed(
  () =>
    rebateTotalByType.value.find((item) => item.type === 'points_rebate')
      ?.amount ?? 0
)

function getRetailerAndOffers(slug: string, offer: any) {
  const { getRetailer, getRetailerOffers } = useRetailer()

  return Promise.all([getRetailer(slug), getRetailerOffers({ slug, offer })])
}

async function initGiftCardItems() {
  isQuickBuyInitializing.value = true

  const {
    slug,
    offerExternalId,
    id,
    price,
    value,
    quantity,
    amount,
    giftRecipientId,
    message,
  } = query.value
  const result = await getRetailerAndOffers(slug, offerExternalId)

  const [[, retailer], offer] = result
  const image = retailer?.attributes?.images?.opt_logo ?? ''
  const product = ((offer as any)?.relationships?.products?.data ?? []).find(
    (o: any) => o.attributes?.uuid === id
  )

  checkout.items = [
    {
      id,
      productImage: image,
      type: 'giftcard',
      price: roundFigure(Number(price || 0)),
      value: parseFloat(value || '0'),
      quantity: quantity || 1,
      name: product?.attributes.name ?? '',
      retailerName: retailer?.attributes?.name ?? '',
    },
  ]

  if (giftRecipientId) {
    checkout.items[0].giftRecipientId = giftRecipientId
    checkout.items[0].message = message
    checkout.items[0].gift = true
  }

  checkout.amount = parseFloat(amount || '0')

  isQuickBuyInitializing.value = false
}

async function initEstoreItems() {
  isQuickBuyInitializing.value = true

  const { getEstoreItem } = useEstore()

  const { id, quantity, value } = query.value
  const response: any = await getEstoreItem(id)
  const item = response.payload

  checkout.items = [
    {
      id,
      productImage: item.images[0],
      type: 'estore',
      priceGst: parseFloat(item.pricing.price_after_tax || 0),
      value: parseFloat(item.pricing.rrp_after_tax || 0),
      quantity: quantity || 1,
      name: item.name,
      retailerName: item.brand,
      shippingFees: parseFloat(item.shipping.shipping_after_tax || 0),
    },
  ]
  checkout.amount = parseFloat(value || '0')
  isQuickBuyInitializing.value = false
}

async function initCinemaItems() {
  isQuickBuyInitializing.value = true

  const { slug, externalId, count, productId, amount } = query.value
  const result = await getRetailerAndOffers(slug, externalId)
  const [[, retailer], offer] = result
  const image = retailer?.attributes?.images?.opt_logo ?? ''

  checkout.items = []

  if (productId) {
    const product = ((offer as any)?.relationships?.products?.data ?? []).find(
      (o: any) => o.id === productId
    )

    if (product) {
      const item = {
        id: product.attributes.uuid,
        productImage: image,
        type: 'voucher',
        price: roundFigure(Number(product.attributes.price || 0)),
        value: parseFloat(product.attributes.value || 0),
        quantity: count,
        name: product.attributes.name ?? '',
        retailerName: retailer?.attributes?.name ?? '',
      }

      checkout.items.push(item)
    }
  }

  checkout.amount = parseFloat(amount || '0')

  isQuickBuyInitializing.value = false
}

async function initDiningItems() {
  isQuickBuyInitializing.value = true

  const { slug, offerExternalId, id, price, value, quantity, amount } =
    query.value
  const result = await getRetailerAndOffers(slug, offerExternalId)

  const [[, retailer]] = result
  const image = retailer?.attributes?.images?.opt_logo ?? ''

  checkout.items = [
    {
      id,
      productImage: image,
      type: 'dining',
      price: roundFigure(Number(price || 0)),
      value: parseFloat(value || '0'),
      quantity: quantity || 1,
      name: retailer?.attributes?.name ?? '',
    },
  ]
  checkout.amount = parseFloat(amount || '0')

  isQuickBuyInitializing.value = false
}

async function initItems(payload: any) {
  type.value = payload.type
  query.value = payload.query

  switch (type.value) {
    case 'estore':
      await initEstoreItems()
      break
    case 'giftcard':
      await initGiftCardItems()
      break
    case 'cinema':
      await initCinemaItems()
      break
    case 'dining':
      await initDiningItems()
      break
    default:
      throw new Error(`Extension type not found: ${type.value}`)
  }

  getQuickBuyRebates()
}

interface PromotionCode {
  promotionCode: string
  amount: string
}

async function verifyPromotionCode(promotionCode: string, amount: number) {
  checkout.isCouponVerifying = true

  try {
    const { data } = await api.post<ApiResponseData<PromotionCode>>(
      `/v3/promotion-codes/${promotionCode}/validate`,
      { amount: amount?.toString() }
    )

    if (data.promotionCode && Number(data.amount) > 0) {
      selectedCoupons.value = [...selectedCoupons.value, data]

      return [null, data]
    } else {
      const error = { message: 'Promotion code validation failed' }

      return [error]
    }
  } catch (error) {
    console.error(error)
    return [error]
  } finally {
    checkout.isCouponVerifying = false
  }
}

async function getQuickBuyRebates() {
  const payload = {
    products: checkout.items.map((item) => {
      return {
        id: item.id,
        quantity: item.quantity,
        type: item.type,
        value: item.price ?? item.priceGst,
      }
    }),
  }

  const response = await api.post<RebatesCalculateResponse>(
    '/v3/purchase-orders/rebates/calculate',
    payload
  )

  rebateTotalByType.value = response.rebateTotalByType ?? []
}

export function useQuickBuyCheckout() {
  return {
    payment: checkout,
    quickBuyProducts,
    isQuickBuyInitializing,
    query,
    type,

    initItems,
    verifyPromotionCode,
    productsHasDelivery,

    cashbackRebateTotal,
    pointsRebateTotal,

    loadingFees,
    transactionFees,
    totalFees,
    subTotal,
    subTotalNoCoupons,
    selectedAmount,
    pointsEarned,
    payWithCard,
    payWithPayId,
    payWithPoints,
    payWithEwallet,
    payWithBankAccount,
    payWithCoupons,
    readyToPay,
    isReadyForPayment,
    selectedPayId,
    selectedCard,
    selectedPoints,
    selectedEwallet,
    selectedBankAccount,
    selectedCoupons,
    selectedPaymentInfo,
    isPointsDisabled,
    isCreditCardsDisabled,
    isBankAccountsDisabled,
    isEwalletDisabled,
    total,
    getTransactionFees,
    getPaymentSources,
    cartAmount,
    isCouponVerifying,
    multipleSourcesSelected,
    hasSelectedPaymentMethods,
    hasCoupons,
    finishPayment,
    resetMethods,
    preview,
    pay,
    reset,
    resetPayment: reset,
    checkoutByAccount,
    verifyPromotionCodeCurrentOrder,
    removeCartPromotionCode,
    isEnoughPointsOrder,
    subTotalWithProgramFees,
    subTotalWithProgramFeesNoCoupons,
    isEnoughPointsPaymentOrder,
    isCardOrBankAccountMethodSelected,
    isAddressSelectionAllowed,
  }
}
