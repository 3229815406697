import { computed, readonly, ref } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import { createDate } from '/~/utils/format/date'
import { usePoints } from '/~/composables/points'

interface PointsExpiry {
  expiryDate: string
}

const { pointsConfig } = usePoints()

const isPointsExpiryLoading = ref(false)
const pointsExpiryDate = ref('')

const isPointsExpiryEnabled = computed(
  () => pointsConfig.value.pointsExpiry?.enabled ?? false
)
const pointsExpiryInMonths = computed(
  () => pointsConfig.value.pointsExpiry?.expiryInMonths ?? 12
)
const showPointsExpiryWarning = computed(
  () => createDate(pointsExpiryDate.value).diff(createDate(), 'month') < 1
)

async function fetchPointsExpiry() {
  if (!isPointsExpiryEnabled.value || isPointsExpiryLoading.value) {
    return
  }

  isPointsExpiryLoading.value = true

  try {
    const response = await api.get<ApiResponseData<PointsExpiry>>(
      '/v3/points/expiry'
    )

    pointsExpiryDate.value = response.data.expiryDate
  } catch (error) {
    console.error('points', error)
  } finally {
    isPointsExpiryLoading.value = false
  }
}

export function usePointsExpiry() {
  return {
    isPointsExpiryLoading: readonly(isPointsExpiryLoading),
    pointsExpiryDate: readonly(pointsExpiryDate),
    isPointsExpiryEnabled,
    pointsExpiryInMonths,
    showPointsExpiryWarning,
    fetchPointsExpiry,
  }
}
