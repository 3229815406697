import { computed } from 'vue'
import emitter from '/~/core/emitter'
import Logger, { LogLevel } from '/~/utils/logger'

const DEFAULT_LOG_LEVEL = (window.eonx || {}).env === 'test' ? 'debug' : 'warn'

const loggerInstances: Record<string, Logger> = {}

function getLogger(moduleName?: string, logLevel?: LogLevel): Logger {
  const key = moduleName || 'default'

  if (!loggerInstances[key]) {
    loggerInstances[key] = new Logger(moduleName, logLevel || DEFAULT_LOG_LEVEL)
  }
  return loggerInstances[key]
}

export function useLogger(moduleName?: string) {
  const logger = getLogger(moduleName)

  const logLevel = computed({
    get: () => logger.getLogLevel(),
    set: (level: LogLevel) => logger.setLogLevel(level),
  })

  emitter.once('main:env-loaded', () => {
    const env = (window.eonx || {}).env

    if (env === 'test') {
      logger.setLogLevel('debug')
    } else {
      logger.setLogLevel('warn')
    }
  })

  function info(...messages: unknown[]) {
    logger.info(...messages)
  }

  function warn(...messages: unknown[]) {
    logger.warn(...messages)
  }

  function error(...messages: unknown[]) {
    logger.error(...messages)
  }

  function debug(...messages: unknown[]) {
    logger.debug(...messages)
  }

  return {
    logLevel,
    info,
    warn,
    error,
    debug,
  }
}
