import { ref, computed, watch } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDollar } from '/~/utils/format/money'
import { getNumberWithOrdinal } from '/~/utils/format/string'
import { useStatementCheckout } from '/~/composables/checkout'
import { useLogger } from '/~/composables/logger'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import {
  useStatementModals,
  useStatementAutoPayments,
  useStatementAccounts,
  StatementAccount,
  Statement,
  StatementAutoPayment,
} from '/~/composables/statements'
import router from '/~/router'

const logger = useLogger('statements-list-item-desk')

const { selectedStatementAutoPayment, deleteStatementAutoPayment } =
  useStatementAutoPayments()
const {
  statementAccounts,
  updateStatementAccountAutoPayment,
  fetchStatementAccounts,
} = useStatementAccounts()
const { removeStatementAccount, showExistingScheduledPaymentModal } =
  useStatementModals()
const { paymentMethods } = usePaymentMethods()

export function useStatementsListItem(props: {
  item?: Statement | null
  account: StatementAccount | null
}) {
  const { payment } = useStatementCheckout()

  const statementAccount = ref<StatementAccount>()
  const isModalOpened = ref(false)
  const isToggleBlocked = ref(false)
  const isPaymentInitializing = ref(false)

  const title = computed(() => {
    const { providerClientName } = useProvider()

    return props.item?.payeeName || providerClientName.value
  })
  const subtitle = computed(() =>
    props.item?.statementDate
      ? `${props.item.statementDateMonthName} statement`
      : null
  )
  const clientName = computed(
    () =>
      statementAccount.value?.name ||
      props.item?.client?.name ||
      props.account?.name ||
      ''
  )

  const clientNumber = computed(
    () => `Account no. ${statementAccount.value?.number}`
  )

  const isBlocked = computed(() =>
    props.item && statementAccount.value?.isShowData
      ? props.item.subtotal === 0 || props.item.isBlocked
      : true
  )

  const subtotal = computed(() =>
    statementAccount.value?.isShowData &&
    (props.item?.subtotal || props.item?.subtotal === 0)
      ? formatDollar(props.item.subtotal)
      : '-'
  )
  const statementAccountId = computed(
    () => props.account?.id || props.item?.statementAccount?.id || ''
  )

  watch(statementAccounts, resetStatementAccount, { deep: true })

  const statementAutoPayment = computed<StatementAutoPayment>(
    () => statementAccount.value?.autoPayment ?? new StatementAutoPayment()
  )
  const isDirectDebitDisabled = computed(
    () => statementAccount.value?.isDirectDebitDisabled
  )

  const isRejected = computed(
    () => statementAccount.value?.isVerifyRejected ?? false
  )

  const autoPayDay = computed(() =>
    statementAccount.value?.autoPayment?.day
      ? getNumberWithOrdinal(statementAccount.value?.autoPayment?.day) +
        ' of each month'
      : '-'
  )
  const isAutoPayToggled = ref(statementAutoPayment.value.isEnabled)

  function resetStatementAccount() {
    // logger.debug('resetStatementAccount', {
    //   statementAccounts: statementAccounts.value,
    //   propsItem: props.item,
    //   propsAccount: props.account,
    // })
    if (props.item) {
      statementAccount.value = statementAccounts.value.find(
        (item) => item.id === props.item?.client?.id
      ) as any
    } else if (props.account) {
      statementAccount.value = props.account
    }

    if (!isModalOpened.value) {
      isAutoPayToggled.value =
        statementAccount.value?.autoPayment?.isEnabled ?? false
    }
  }

  const paymentMethod = computed(
    () =>
      paymentMethods.value.find(
        (item: any) =>
          item.id ===
          (statementAutoPayment.value as any)._paymentMethods?.[0]?.id
      ) ?? null
  )

  async function changeToggle(value: boolean) {
    if (value && !statementAutoPayment.value.isEnabled) {
      enableDirectDebit()
    } else if (!value && statementAutoPayment.value.isEnabled) {
      disableDirectDebit()
    }
  }

  async function enableDirectDebit() {
    isToggleBlocked.value = true
    isModalOpened.value = true

    logger.debug('set selectedStatementAutoPayment', {
      statementAutoPayment: statementAutoPayment.value,
    })

    selectedStatementAutoPayment.value = statementAutoPayment.value as any
    ;(selectedStatementAutoPayment.value as any).statement = props.item

    if (ui.mobile) {
      router.push({
        name: 'statement-direct-debit',
        params: { id: statementAccountId.value },
      })
    } else {
      modal.show('auto-pay', {
        on: {
          hide: () => {
            isModalOpened.value = false
            isToggleBlocked.value = false
            isAutoPayToggled.value = false
          },
        },
      })
    }
  }

  function disableDirectDebit() {
    let confirmed = false

    isToggleBlocked.value = true

    const options = {
      props: {
        title: 'Disable Direct Debit',
        description:
          'Are you sure you want to disable Direct Debit for this selected account?',
        onConfirm: async () => {
          try {
            await deleteStatementAutoPayment(statementAutoPayment.value.id)
            await updateStatementAccountAutoPayment(statementAccountId.value)
            confirmed = true
          } catch (error) {
            console.error('statements', error)
          } finally {
            await fetchStatementAccounts({
              includeAutoPayments: true,
              includeNextDueStatementOrders: true,
            })
          }
        },
      },
      on: {
        hide: () => {
          isToggleBlocked.value = false
          if (!confirmed) {
            isAutoPayToggled.value = true
          }
        },
      },
    }

    if (ui.mobile) {
      bottomSheet.show('confirm', options)
    } else {
      modal.show('confirm', options)
    }
  }

  function showEditAccountModal() {
    const account =
      statementAccount.value || props.item?.client || props.account

    if (!account) {
      return
    }

    if (ui.mobile) {
      bottomSheet.show('edit-account', {
        props: {
          account,
        },
      })
    } else {
      modal.show('edit-account', {
        props: {
          account,
        },
      })
    }
  }

  function showRemoveAccountModal() {
    const account =
      statementAccount.value || props.item?.client || props.account

    if (account) {
      removeStatementAccount(account as any)
    }
  }

  async function onPay() {
    if (props.item?.isScheduled) {
      showExistingScheduledPaymentModal(props.item)
      return
    }

    if (!props.item?.active || props.item.isBlocked) {
      return
    }

    isPaymentInitializing.value = true
    try {
      await payment.initPayment({
        statement: props.item,
      })
    } catch (error) {
      console.error('statements', error)
    } finally {
      isPaymentInitializing.value = false
    }

    const { isBillPaymentsV15Template } = useProvider()

    if (isBillPaymentsV15Template.value) {
      router.push({
        name: 'payments-make',
        params: {
          payingTo: {
            amount: props.item.subtotal,
            payeeId: props.item.payeeId,
          },
        },
      })
    } else {
      router.push({
        name: 'statement-checkout',
        params: {
          id: props.item.id,
        },
      })
    }
  }

  const menuItems = computed(() => [
    {
      icon: 'heroicons/solid/pencil-square',
      content: 'Edit',
      disabled: isRejected.value,
      click: () => {
        showEditAccountModal()
      },
    },
    {
      icon: 'heroicons/solid/trash',
      content: 'Remove',
      click: () => {
        showRemoveAccountModal()
      },
    },
  ])

  resetStatementAccount()

  return {
    title,
    subtitle,
    subtotal,
    menuItems,
    autoPayDay,
    clientName,
    clientNumber,
    paymentMethod,
    statementAccount,

    isBlocked,
    isRejected,
    isDirectDebitDisabled,
    isAutoPayToggled,
    isToggleBlocked,
    isPaymentInitializing,

    showRemoveAccountModal,
    showEditAccountModal,
    disableDirectDebit,
    changeToggle,
    onPay,
  }
}
