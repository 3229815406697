export const datePickerFormats = {
  daymonth: 'MMM D',
  daymonthyear: 'MMM D, YYYY',
  daymonthyearnumeric: 'YYYY/MM/DD',
  daymonthyeartime: 'MMM D, YYYY HH:mm a',
  daymonthyearlong: 'MMMM D YYYY',
  daymonthyearlongtime: 'MMMM D YYYY HH:mm a',
  daymonthyearfulltime: 'MMM D, YYYY HH:mm:ss',
  daymonthyearlongfulltime: 'MMMM D YYYY HH:mm:ss',
}
