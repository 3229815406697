import { computed, ref, reactive } from 'vue'
import { ApiResponseData, type StatementAutoPaymentRawData } from '/~/types/api'
import api from '/~/core/api'
import { cardCode } from '/~/utils/cards'
import { roundFigure } from '/~/utils/format/numeric'
import { useExtensions } from '/~/composables/extensions'
import { useLocalization } from '/~/composables/localization'
import { useLogger } from '/~/composables/logger'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { StatementAutoPayment, useStatements } from '/~/composables/statements'

interface PaymentMethod {
  type: PaymentMethodType.creditCard | PaymentMethodType.bankAccount
  name?: string
  number?: string
  bsb?: string
  accountNumber?: string
  accountName?: string
  calculatedAmount?: number
  msfConfig: {
    fixedFeeRate: string
    percentageFeeRate: string
  }
  // Additional properties can be added as needed
}

const { translate } = useLocalization()
const logger = useLogger('useStatementAutoPayments')

const isPayNow = ref<boolean>(false)

const isAutoPayEnabled = computed<boolean>(() => {
  const { getConfigByName } = useExtensions()

  return getConfigByName('auto-pay')?.statements?.enabled ?? false
})

const isStatementAutoPaymentsLoading = ref<boolean>(false)
const statementAutoPayments = ref<StatementAutoPayment[]>([])

interface StatementAutoPaymentCreatePayload {
  statementAccountId: string
  day: number
  paymentMethods: PaymentMethod[]
}

interface StatementAutoPaymentUpdatePayload {
  statementAccountId: string
  paymentMethods: PaymentMethod[]
}

interface PaymentMethod {
  id: string
}

async function createStatementAutoPayment(
  payload: StatementAutoPaymentCreatePayload
) {
  isStatementAutoPaymentsLoading.value = true

  try {
    const response = await api.post<
      ApiResponseData<StatementAutoPaymentRawData>
    >('/v3/statement-accounts/auto-payments', payload)

    return new StatementAutoPayment(response.data)
  } catch (error) {
    console.error('statements', error)
    throw error
  } finally {
    isStatementAutoPaymentsLoading.value = false
  }
}

async function updateStatementAutoPayment(
  id: string,
  payload: StatementAutoPaymentUpdatePayload
): Promise<void> {
  isStatementAutoPaymentsLoading.value = true

  try {
    await api.put(`/v3/statement-accounts/auto-payments/${id}`, payload)
  } catch (error) {
    console.error('statements', error)
    throw error
  } finally {
    isStatementAutoPaymentsLoading.value = false
  }
}

async function deleteStatementAutoPayment(id: string) {
  isStatementAutoPaymentsLoading.value = true

  try {
    await api.delete(`/v3/statement-accounts/auto-payments/${id}`)
  } catch (error) {
    console.error('statements', error)
    throw error
  } finally {
    isStatementAutoPaymentsLoading.value = false
  }
}

async function fetchStatementAutoPayments() {
  isStatementAutoPaymentsLoading.value = true

  try {
    const response = await api.get<
      ApiResponseData<StatementAutoPaymentRawData[]>
    >('/v3/statement-accounts/auto-payments?perPage=1000')

    logger.debug('fetchStatementAutoPayments', response)

    const items = (response.data ?? []).map(
      (autoPayment: StatementAutoPaymentRawData) =>
        new StatementAutoPayment(autoPayment)
    )

    statementAutoPayments.value.splice(
      0,
      statementAutoPayments.value.length,
      ...items
    )
  } catch (error) {
    console.error('statements', error)
    throw error
  } finally {
    isStatementAutoPaymentsLoading.value = false
  }
}

async function hasAccountAutoPayment(accountId: string) {
  try {
    await fetchStatementAutoPayments()
  } catch (error: any) {
    console.error('statement', error)
  }

  return (
    statementAutoPayments.value.find(
      (item) => item.statementAccountId === accountId
    )?.isEnabled ?? false
  )
}

async function hasDirectDebitOrScheduled(
  accountId: string | undefined,
  orderId: string | undefined
) {
  const { fetchStatementOrder } = useStatements()

  try {
    if (accountId) {
      const hasDirectDebit = await hasAccountAutoPayment(accountId)

      if (hasDirectDebit) {
        return true
      }
    }

    if (orderId) {
      const order = await fetchStatementOrder(orderId)

      if (order.isScheduled) {
        return true
      }
    }
  } catch (error) {
    console.error(error)
  }

  return false
}

// AUTOPAY flow
const payWith = reactive({
  card: null as PaymentMethod | null,
  bankAccount: null as PaymentMethod | null,
})

const selectedStatementAutoPayment = ref<StatementAutoPayment | null>(null)

const subTotal = computed(() =>
  roundFigure(selectedStatementAutoPayment.value?.subtotal)
)
const subTotalWithProgramFees = computed(() =>
  roundFigure(selectedStatementAutoPayment.value?.subtotalWithProgramFees)
)

function selectPaymentMethod(paymentMethod: PaymentMethod) {
  if (paymentMethod.type === PaymentMethodType.creditCard) {
    selectedCard.value = paymentMethod
  }

  if (paymentMethod.type === PaymentMethodType.bankAccount) {
    selectedBankAccount.value = paymentMethod
  }
}

const selectedCard = computed({
  get: () => payWith.card,
  set: (val) => {
    if (val) {
      payWith.card = {
        ...val,
        calculatedAmount: subTotal.value,
      }

      logger.debug(
        'selectCard set selectedStatementAutoPayment paymentMethods',
        val
      )
      if (selectedStatementAutoPayment.value) {
        selectedStatementAutoPayment.value.paymentMethods = [val]
      }
      payWith.bankAccount = null
    } else {
      payWith.card = null
    }
  },
})

const selectedBankAccount = computed({
  get: () => payWith.bankAccount,
  set: (val) => {
    if (val) {
      payWith.bankAccount = {
        ...val,
        calculatedAmount: subTotal.value,
      }

      logger.debug(
        'selectedBankAccount set selectedStatementAutoPayment paymentMethods',
        val
      )
      if (selectedStatementAutoPayment.value) {
        selectedStatementAutoPayment.value.paymentMethods = [val]
      }
      payWith.card = null
    } else {
      payWith.bankAccount = null
    }
  },
})

const payWithCard = computed(() =>
  roundFigure(selectedCard.value?.calculatedAmount ?? 0)
)

const payWithBankAccount = computed(() =>
  roundFigure(selectedBankAccount.value?.calculatedAmount ?? 0)
)

function getTransactionFees() {
  return selectedStatementAutoPayment.value?.getTransactionFees?.()
}

const transactionFees = computed(
  () => selectedStatementAutoPayment.value?.transactionFees
)
const loadingFees = computed(
  () =>
    selectedStatementAutoPayment.value?.isLoadingProgramFees ||
    selectedStatementAutoPayment.value?.isLoadingTransactionFees
)

const hasSelectedPaymentMethods = computed(() => {
  return Boolean(payWith.card || payWith.bankAccount)
})

function unselectPayments() {
  payWith.card = null
  payWith.bankAccount = null
}

const selectedPaymentInfo = computed(() => {
  const items = [
    payWithCard.value && selectedCard.value,
    payWithBankAccount.value && selectedBankAccount.value,
  ].filter((i) => i) as PaymentMethod[]

  if (!items.length) {
    return {
      icon: 'v2/custom/credit-card-outline',
      iconSize: 24,
      title: 'Select or Add Payment Method',
    }
  }

  let icon
  let text
  const titles = []

  for (const item of items) {
    switch (item.type) {
      case PaymentMethodType.creditCard:
        titles.push(items.length !== 1 ? 'Credit Card' : item.name)
        text = `**** **** **** ${item.number.slice(-4)}`
        icon = `cards/${cardCode(item)}`
        break
      case PaymentMethodType.bankAccount:
        titles.push(items.length !== 1 ? 'Bank Account' : item.accountName)
        text = translate('bankAccount.details', {
          acc: `*** *** *${item.accountNumber.slice(-2)}`,
          bsb: item.bsb,
        })
        icon = 'v2/custom/bank'
        break
    }
  }

  return {
    icon: items.length !== 1 ? 'payment_methods' : icon,
    text: items.length !== 1 ? 'Split payment' : text,
    title: titles.join(' + '),
    iconClass:
      icon === 'symbion/token'
        ? 'text-primary'
        : items.length
        ? 'text-primary'
        : '',
  }
})

export function useStatementAutoPayments() {
  return {
    isAutoPayEnabled,
    isStatementAutoPaymentsLoading,
    statementAutoPayments,
    createStatementAutoPayment,
    updateStatementAutoPayment,
    deleteStatementAutoPayment,
    fetchStatementAutoPayments,
    isPayNow,

    selectedStatementAutoPayment,
    getTransactionFees,
    selectedCard,
    selectedBankAccount,
    payWithCard,
    payWithBankAccount,
    subTotal,
    subTotalWithProgramFees,
    transactionFees,
    loadingFees,
    hasSelectedPaymentMethods,
    unselectPayments,
    selectedPaymentInfo,
    selectPaymentMethod,
    hasDirectDebitOrScheduled,
    hasAccountAutoPayment,
  }
}
